

const requiredFields = {
    'subLocationName' : 'Sublocation Name',
    'location' : 'Locattion',
    'subLocationID' : 'Sublocation ID',
    'commisionRate' : 'Commission Rate %',
    'posSystem' : 'POS System',
}

export default {
    data() {
        return {
            error: '',
        }
    },
    methods: {
        validateForm() {
            for (const key in requiredFields) {
                if (this.partner[key] === undefined || this.partner[key] === '') {
                    this.error = `Missing Required Field ${requiredFields[key]}.`
                    return false
                }
            }

            this.error = undefined
            return true
        }
    }
}