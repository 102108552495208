<style lang="scss">
    @import '~scss/variables';
    @import '~scss/mixins';
    .form-input {
        &__count {
            font-size: fz(xs);
            color: $grey_mid_1;
        }

        input[type="text"],
        input[type="number"],
        input[type="date"],
        input[type="password"],
        input[type="email"],
        input[type="time"] {
            border: 0;
            padding: spacing(xxs) spacing(xs);

            &:not(.bare) {
                border: 1px solid $border_grey;
                &:focus {
                    box-shadow: 0px 0px 5px #7FD7FF;
                }
            }

            &.full {
                width: 100%;
            }
        }

        label {
            padding-left: 0px;
        }

        .label--md {
            width: 100px;
        }

        .label--partner-form {
            width: 210px;
        }

        .required:after {
            color: #FF0000;
            content: ' *';
            display:inline;
        }

        .autocomplete {
            position: relative;
            display: inline-block;
        }

        .autocomplete-items {
            position: absolute;
            border: 1px solid #d4d4d4;
            border-bottom: none;
            border-top: none;
            z-index: 99;
            /*position the autocomplete items to be the same width as the container:*/
            top: 100%;
            left: 0;
            right: 0;
            max-height: 100px;
            overflow: auto;
            margin-top: 5px;
        }

        .autocomplete-items div {
            padding: 10px;
            cursor: pointer;
            background-color: #fff; 
            border-bottom: 1px solid #d4d4d4; 
        }

    }
</style>
<template>
    <div class="form-input">
        <label 
            v-if="label"
            :for="id"
            :class="labelClass"
        > {{ label }} </label>
        <div class="autocomplete">
            <input
                :id="id"
                ref="inputField"
                :class="{hasValue, bare, full}"
                type="text"
                :value="inputValue"
                @focus="hasFocus = true"
                @blur="hide"
                @input="onInput"
            >
        
            <div 
                v-if="hasFocus"
                class="autocomplete-items"
            >
                <div 
                    v-for="option in filteredOptions"
                    :key="option"
                    @click="onDropdownSelect(option)"
                >
                    <span>{{ option }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DropdownFormInput',
    data: function () {
        return {
            id: `form-input-${Math.random().toString(36).substring(7)}`,
            hasValue: Boolean(this.value),
            searchQuery: "",
            hasFocus: false,
        };
    },
    props: [
        'autofocus',
        'disabled',
        'label',
        'max',
        'maxlength',
        'min',
        'pattern',
        'placeholder',
        'readonly',
        'required',
        'size',
        'step',
        'type',
        'value',
        'showCount',
        'bare',
        'spellcheck',
        'full',
        'labelSize',
        'options',
        'optionSplitter',
    ],
    computed: {
        inputValue() {
            if (!this.hasFocus)
                return this.value

            return this.searchQuery
        },
        labelClass() {
            const labelClass = this.labelSize ? `label--${this.labelSize}` : 'label--md'
            const required = this.required ? this.required : false
            let classes = {
                empty: this.hasValue,
                required: required
            }

            classes[labelClass] = true

            return classes
        },

        filteredOptions() {
            if (this.searchQuery == "")
                return this.options.map(el => el.display)
                
            return this.options.filter(el => {
                return el.searchable.toLowerCase().startsWith(this.searchQuery.toLowerCase())
            }).map(el => el.display)
        },
    },
    methods: {
        onInput(e) {
            this.searchQuery = e.target.value
        },
        onDropdownSelect(option) {
            this.$emit('input', option)
            this.value = option
            this.hasFocus = false
            this.searchQuery = ''

        },
        hide() {
            setTimeout(() => {
                this.hasFocus = false
            }, 200)
        }
    },
}
</script>
