<template>
    <form class="sublocation-form">
        <LineItem
            v-if="sublocation"
            class="row no-gutters"
        >
            <div class="col-12">
                <FormInput
                    v-model="sublocation.subLocationName"
                    class="large block-xs--xs"
                    label="Sublocation Name"
                    spellcheck="true"
                    required="true"
                    labelSize="partner-form"
                />
                <DropdownFormInput
                    v-model="location"
                    required="true"
                    class="large block-xs--xs block-xs--xs-bottom"
                    label="Location"
                    :options="airportOptions"
                    labelSize="partner-form"
                />
                <FormInput
                    v-model="computedSublocationID"
                    class="large block-xs--xs"
                    label="Sublocation ID"
                    required="true"
                    labelSize="partner-form"
                    :disabled="true"
                />
                <FormInput
                    v-model="commissionRate"
                    class="large block-xs--xs"
                    label="Commission Rate %"
                    spellcheck="true"
                    required="true"
                    labelSize="partner-form"
                    :constrainingFunc="commissionsConstrainingFunc"
                />
                <FormInput
                    v-model="commisionRateExpiration"
                    class="large block-xs--xs"
                    label="Commission Rate Expiration"
                    labelSize="partner-form"
                    :constrainingFunc="dateConstrainingFunc"
                    :keyUpFunc="dateFormat"
                />
                <FormInput
                    v-model="ccProcessingCap"
                    class="large block-xs--xs"
                    label="CC Processing Cap"
                    spellcheck="true"
                    labelSize="partner-form"
                    :constrainingFunc="ccProcessingCapConstrainingFunc"
                />
                <FormInput
                    v-model="sublocation.connectivityFunding"
                    class="large block-xs--xs"
                    label="Connectivity Funding"
                    spellcheck="true"
                    labelSize="partner-form"
                />
                <FormInput
                    v-model="sublocation.marketingBudget"
                    class="large block-xs--xs"
                    label="Marketing Budget"
                    spellcheck="true"
                    labelSize="partner-form"
                />
                <SelectInput
                    v-model="posSystem"
                    :options="posOptions"
                    class="block-xs--xs"
                    label="POS System"
                    required="true"
                    labelSize="partner-form" 
                    excludeBlank="true"
                />
            </div>
        </LineItem>
    </form>

</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import moment from 'moment'

import FormInput from 'components/cleverly/FormInput.vue'
import LineItem from 'components/line_item.vue'
import SelectInput from 'components/cleverly/SelectInput.vue'
import DropdownFormInput from 'components/DropdownFormInput.vue'

export default {
    name: 'SublocationForm',
    components: {
        FormInput,
        LineItem,
        SelectInput,
        DropdownFormInput,
    },
    props: {
        sublocation: {
            type: Object,
            default: () => {},
        },
        allSublocations: {
            type: Array,
            default: () => [],
        },
        countryCode: {
            type: String,
            default: ''
        },
        partnerShortCode: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            tmpDate: this.sublocation.commisionRateExpiration == '' ? '' : moment.utc(this.sublocation.commisionRateExpiration).format('MM/DD/YYYY'),
        }
    },
    computed: {
        commissionRate: {
            get() {
                return String(this.sublocation.commisionRate)
            },
            set(newValue) {
                this.sublocation.commisionRate = Number(newValue)
            }
        },
        commisionRateExpiration: {
            get() {
                return this.tmpDate
            },
            set(newValue) {
                this.tmpDate = `${newValue}`

                if (this.tmpDate.length == 10) {
                    this.sublocation.commisionRateExpiration = moment.utc(this.tmpDate, 'MM/DD/YYYY').format('[/Date(]xZZ[))/]')
                }
            }
        },
        ccProcessingCap: {
            get() {
                return String(this.sublocation.ccProcessingCap)
            },
            set(newValue) {
                this.sublocation.ccProcessingCap = Number(newValue)
            }
        },
        location: {
            get() {
                if (this.sublocation.location == '') return ''

                return `${this.sublocation.location} - ${this.airportName}`
            },
            set(newValue) {
                this.sublocation.location = newValue.split(' - ')[0]
            }
        },
        posSystem: {
            get() {
                return this.sublocation.posSystem
            },
            set(newValue) {
                const system = this.posSystems.find(el => el.posSystem == newValue)
                this.sublocation.posSystem = system.posSystem
                this.sublocation.posSystemID = system.posSystemID
            }
        },
        posOptions() {
            return this.posSystems.map(el => {
                return {
                    text: el.posSystem,
                    value: el.posSystem,
                }
            })
        },
        computedSublocationID() {
            if (this.sublocation.subLocationID != '') return this.sublocation.subLocationID

            if (this.sublocation.location == '') return ''

            const number = this.calculateSublocationNumber()

            return `${this.countryCode}_${this.partnerShortCode}_${this.sublocation.location}_${number}`
        },
        airportName() {
            const airport = this.airports.find(el => el.airportIdent == this.sublocation.location)

            return airport ? airport.airportName : 'Unknown Airport'
        },
        airportOptions() {
            return this.airports.map(el => {
                return {
                    display: `${el.airportIdent} - ${el.airportName}`,
                    searchable: el.airportName,
                }
            })
        },
        ...mapGetters('PartnerStore', [
            'posSystems',
            'airports',
        ]),
    },
    methods: {
        dateFormat(e) {
            const value = e.target.value
            const key = e.keyCode
            if (value.length > 10) return false
            if (key == 8) return true
            const chars = value.split('')
            if (value.length == 3 && chars[2] != '/') {
                this.tmpDate = `${chars[0]}${chars[1]}/${chars[2]}`
            }
            if (value.length == 6 && chars[5] != '/') {
                this.tmpDate = `${chars[0]}${chars[1]}/${chars[3]}${chars[4]}/${chars[5]}`
            }
            if (value.length == 2 || value.length == 5) {
                this.tmpDate = `${value}/`
            }
            return true
        },
        commissionsConstrainingFunc(event) {
            const charCode = (event.which) ? event.which : event.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                event.preventDefault()
                return false
            } else {
                return true
            }
        },
        ccProcessingCapConstrainingFunc(event) {
            const charCode = (event.which) ? event.which : event.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                event.preventDefault()
                return false
            } else {
                return true
            } 
        },
        dateConstrainingFunc(event) {
            const charCode = (event.which) ? event.which : event.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) || event.target.value.length == 10) {
                event.preventDefault()
                return false
            } else {
                return true
            }
        },
        calculateSublocationNumber() {
            if (this.sublocation.location == '') return ''

            const count = this.allSublocations.filter(el => el.location == this.sublocation.location).length

            var s = String(count + 1);
            while (s.length < 3) {s = "0" + s;}
            return s;
        },
    }
}
</script>

<style lang="scss">
    @import '~scss/variables';
    @import '~scss/mixins';

    .sublocation-form {

        textarea {
            @include below(md) {
                width: 100%;
            }
            
        }
        input {
            @include below(xs) {
                width: 100%;
            }
        }

        .form-input {
            input {
                @include above(md) {
                    width: 331px;
                }
            }
        }

        .vselect-input {
            input {
                @include above(md) {
                    width: 305px;
                }

                @include below(xs) {
                    width: 100%;
                }
            }
        }

        .select-input {
            select {
                @include above(md) {
                    width: 331px;
                }
            }
        }

        .block-label > label {
            @include roboto-bold;
            display: block;
            font-size: fz(sm);
            color: $grey_mid_1;
            margin: spacing(xs) 0px;
            width: auto;
        }

        & > .line-item {
            padding: 0px 30px;
            margin-right: 0;
        }

        .label-offset {
            margin-left: 155px;
            &.line-item {
                margin-right: -30px;
                &:before {
                    display: none;
                }
            }
        }
    }
</style>

