var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-input" }, [
    _vm.label
      ? _c("label", { class: _vm.labelClass, attrs: { for: _vm.id } }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ])
      : _vm._e(),
    _c("div", { staticClass: "autocomplete" }, [
      _c("input", {
        ref: "inputField",
        class: { hasValue: _vm.hasValue, bare: _vm.bare, full: _vm.full },
        attrs: { id: _vm.id, type: "text" },
        domProps: { value: _vm.inputValue },
        on: {
          focus: function ($event) {
            _vm.hasFocus = true
          },
          blur: _vm.hide,
          input: _vm.onInput,
        },
      }),
      _vm.hasFocus
        ? _c(
            "div",
            { staticClass: "autocomplete-items" },
            _vm._l(_vm.filteredOptions, function (option) {
              return _c(
                "div",
                {
                  key: option,
                  on: {
                    click: function ($event) {
                      return _vm.onDropdownSelect(option)
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(option))])]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }